body {
  background: #fff;
  width: 100vw;
  font-family: "Roboto", sans-serif;
}
/* Carl you are silly, work out correct global loading here when have time please */
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body li,
body div,
body input,
body span {
  font-family: "Roboto", sans-serif;
}

body.loading {
  overflow: hidden;
}

#loading {
  z-index: 9999;
  min-width: 1280px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  transform: 0.5;
}
@media screen and (max-width: 420px) {
  #loading {
    height: 120vh;
  }
}

#loading.loaded {
  opacity: 0;
  z-index: 0;
  display: none;
}

.blur-background {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.min-w-1280 {
  min-width: 1280px;
}

.hero-gradient {
  background: linear-gradient(
    to top,
    rgba(57, 57, 57, 1) 0%,
    rgba(0, 0, 0, 0) 50%
  );
}

table td {
  height: 50px;
}

.css-selector {
  background: linear-gradient(304deg, #00b284, #4cffd1, #00b284);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes changeOpacity {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.66;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.66;
  }
  100% {
    opacity: 1;
  }
}
.pulse {
  animation: biggerSmaller 0.9s ease 0s infinite;
}
@keyframes biggerSmaller {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.075);
  }
}

#second-logo-wrapper-1 {
  height: 80px;
  margin-top: 20px;
  width: 250px;
  display: flex;
}

#second-logo-wrapper-2 {
  display: flex;
  height: 100%;
  width: 100%;
}

#second-logo {
  display: block;
  max-height: 100%;
  width: auto;
}

#first-logo {
  max-height: 330px;
}